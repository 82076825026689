// colore barra progress in pagine CT
.public-ui .link-list-wrapper.menu-link-list h3:after,
.public-ui .link-list-wrapper.menu-link-list h4:after {
  background-color: $secondary;
}

// sfondo blocco elenco
.block.listing {
  .public-ui .full-width.bg-light {
    background-color: $primary-a0 !important;
  }
}

// colore icona pagina Argomento
.contenttype-pagina-argomento {
  .ArgomentoTitleWrapper {
    svg {
      fill: $secondary;
    }
  }
}

// size blocco immagine per responsive
.block.image.align.full {
  img.full-width {
    @include mobile(320px, 1023px) {
      height: 14rem;
    }
  }
}
