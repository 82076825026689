.it-header-wrapper {
  .it-header-center-wrapper {
    @media (min-width: 768px) and (max-width: 991px) {
      height: auto;
    }

    .it-header-center-content-wrapper {
      margin-left: 1.38em;
      .it-brand-wrapper {
        @include mobile(320px, 411px) {
          padding-left: 0.5em !important;
        }

        @include mobile(768px, 1023px) {
          padding-left: 0 !important;
        }
        .it-brand-text {
          @include mobile(320px, 411px) {
            padding-right: 0em !important;
          }
          h3.no_toc.d-none.d-md-block {
            @include mobile(768px, 1023px) {
              display: none !important;
            }
          }
        }
      }
    }
  }

  .it-header-slim-wrapper-content {
    a.btn-icon:hover {
      color: $white;
    }
  }
}

.block.full-width {
  .public-ui.searchSections {
    .searchSections-background {
      background-color: rgba(63, 65, 66, 0.37);
    }
    div.container {
      .searchContainer.d-flex.w-100 {
        .buttonsContainer.mt-2.d-flex {
          button.btn.btn-primary.btn-sm {
            background-color: rgba(255, 255, 255, 0.77);
            color: $primary;
            font-size: 18px;
            line-height: 27px;
          }
          button.btn.btn-primary.btn-sm:hover {
            background-color: $primary-button-color;
          }
        }
      }
    }
  }
}

.public-ui .btn.btn-tertiary {
  background-color: $tertiary;
  color: $white !important;

  svg {
    fill: $white;
  }
}

.public-ui .btn-tertiary:hover {
  background-color: lighten($color: $tertiary, $amount: 5%);
  color: $white !important;

  svg {
    fill: $white;
  }
}

.block.highlitedContent {
  .card-body {
    .card-title {
      a {
        color: $primary;
        font-size: 40px;
        line-height: 48px;
      }
    }

    p.card-text {
      font-family: 'Titillium Web';
      font-size: 18px;
      line-height: 28px;
      color: $body-text-color;
    }

    a.read-more {
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 1.29px;
      text-decoration: none;
      color: $secondary;

      svg {
        width: 26px;
        height: 26px;
        fill: $secondary;
      }
    }
  }
}

.block {
  .argumentInEvidence {
    .card {
      .card-body {
        h3.card-title {
          @include mobile(768px, 1023px) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.sliderTemplate {
  .slider-container {
    .slick-slide {
      .slide-wrapper {
        .slide-title {
          background-color: transparentize($secondary, 0.3);
          font-size: 23px;
          line-height: 24px;
          font-weight: 600;
        }
      }
    }
  }
}

.contenttype-patologia,
.contenttype-biblioteca {
  .content-area {
    #page-document {
      @include mobile(320px, 767px) {
        h1.documentFirstHeading {
          padding: 0em 0.5em;
        }
        p {
          padding: 0em 1.1em;
        }
      }
      @include mobile(768px, 1280px) {
        h1.documentFirstHeading {
          padding: 0em 0.5em;
        }
        p {
          padding: 0em 1.5em;
        }
      }
    }
  }
}

.block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  a.btn-outline-info:hover,
.block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  a.btn-outline-info:focus,
.block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  .public-ui
  .toggles
  label
  input[type='checkbox']:focus
  + a.btn-outline-info.lever,
.public-ui
  .toggles
  label
  .block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  input[type='checkbox']:focus
  + a.btn-outline-info.lever,
.block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  .public-ui
  .toggles
  label
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + a.btn-outline-info.lever,
.public-ui
  .toggles
  label
  .block.cta-block
  .cta-block-wrapper.has-image
  .cta-tile-text
  input[type='checkbox']:focus
  + input[type='checkbox'].lever
  + a.btn-outline-info.lever {
  background: $tertiary-button-color;
  color: $white;
}

.block.cta-block.cta-block
  .cta-block-wrapper
  .cta-tile-text
  a.btn-outline-info {
  box-shadow: inset 0 0 0 1px $tertiary-button-color;
  background-color: $tertiary-button-color !important;
  color: $white;
  padding: 18px 60px;
  font-weight: bold;
  font-size: 1rem;
}

.public-ui .btn.btn-tertiary,
.public-ui .subsite-header .text a.btn-tertiary,
.subsite-header .text .public-ui a.btn-tertiary {
  background-color: #9f1717;
  color: $white !important;
  svg {
    fill: $white;
  }
}
