.it-header-slim-wrapper {
  background-color: #01375d;
}

.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    .it-brand-wrapper {
      a {
        .icon {
          width: 80px;
          height: auto;
        }
      }

      .it-brand-text {
        h2,
        h3 {
          color: $primary;
          font-weight: 700;
        }
        h2 {
          margin: 0px;
        }
        h3 {
          font-weight: 500;
          font-size: 24px;
        }
      }
    }

    .it-search-wrapper {
      a.rounded-icon {
        background: white;
        box-shadow: 0 2px 8px 0 transparentize($color: #3b3b3b, $amount: 0.76);
        border-radius: 50%;
        width: 48px;
        height: 48px;
        transition: 0.3s ease all;

        svg {
          fill: $primary;
        }

        &:hover {
          background: darken($primary, 5%);

          svg {
            fill: white;
          }
        }
      }
    }
  }
}

button.custom-navbar-toggler svg.icon {
  fill: $primary;
}

.it-footer .it-footer-main {
  background-color: $primary-dark;

  .it-brand-wrapper {
    a {
      .icon {
        width: 110px;
        height: auto;
      }
      .it-brand-text {
        h2,
        h3 {
          font-weight: 700;
        }

        h3 {
          display: none !important;
        }
      }
    }
  }
}

.it-footer-small-prints {
  background-color: $primary-dark;
}

@media (min-width: map-get($map: $grid-breakpoints, $key: 'lg')) {
  .it-header-navbar-wrapper {
    background-color: $secondary;
  }

  .it-header-center-wrapper {
    height: 165px;

    .it-header-center-content-wrapper {
      .it-brand-wrapper {
        a {
          .icon {
            width: 110px;
          }
        }
      }
    }
  }
}

.public-ui .it-header-slim-right-zone.header-slim-right-zone .btn-full {
  flex: unset !important;
}
