//site
$primary-h: 205;
$primary-s: 100;
$primary-b: 45;

$white: #fff;
$black: #000;

$primary: #004372;
$primary-dark: #01375d;

$secondary: #065994;
$tertiary: #9f1717;
//$tertiary-text: $white;

$header-center-bg-color: $white;
$header-center-text-color: $primary;

$primary-a0: hsl($primary-h, 62%, 97%) !default;
$primary-a0-saturated: hsl($primary-h, 80%, 95%) !default;

//$headings-color: $primary;
//$semibold: 600;s

$primary-text-color: #000;
$body-text-color: #3f4142;

$primary-button-color: $white;
$secondary-button-color: $secondary;
$tertiary-button-color: #9f1717;
